
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const formRef = ref<null | HTMLFormElement>(null);
    const addRFQmodel = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const rules = ref<any>({});
    const supplier_data = ref<Array<Supplier>>([]);
    const consignee_branch_data = ref([]);
    const po_data = ref([]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const tableData = ref<Array<Product>>([]);

    const selectedOrdersProducts = ref<Array<any>>([]);
    const tempOrderQty = ref("");

    interface Product {
      purchase_order_details_id: string;
      purchase_order_no: string;
      purchase_order_date: string;
      supplier_company: string;
      consignee_warehouese: string;
      category_name: string;
      product_name: string;
      payment_term: string;
      incoterm: string;
      delivery_term: string;
      order_qty: string;
      remainin_qty: string;
      dispatch_quantity: string;
      isCheck: boolean;
      uom: string;
    }

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    const formData = ref({
      supplier_select: "",
      supplier_warehouse_select: "",
      delivery_from:"",
      po_no_select: [],
      remark:'',
      is_moving_goods_select: true,
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs("New Dispatch Order", []);
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());

      let requestData = {
        user_id: user.user_id,
        supplier_company_id: data.supplier_select,
        supplier_company_branch_id: data.supplier_warehouse_select,
        delivery_from: data.delivery_from,
        moving_goods_yn: data.is_moving_goods_select ? 1 : 0,
        product_details: selectedOrdersProducts?.value,
        remark: data?.remark
      };

      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_PURCHASE_DISPATCH_ORDER, requestData)
          .then(({ data, is_error, status, message }) => {
            loading.value = false;
            if (data?.is_success && status !== 500){
            setTimeout(() => {
              Swal.fire({
                html: ""+
                      data?.do_no+
                      "<br>Purchase dispatch order created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-purchase-dispatch-order-listing" });
              });
            }, 2000);
            }else if(data == "" && is_error == false && status == 500){
              Swal.fire({
                  text: message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-purchase-dispatch-order-listing" });
                });
          }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      debugger;
      loading.value = true;
      rules.value = {
        supplier_select: [
          {
            required: true,
            message: "Supplier is required",
            trigger: "change",
          },
        ],
        supplier_warehouse_select: [
          {
            required: true,
            message: "Warehouse is required",
            trigger: "change",
          },
        ],
        // delivery_from: [
        //   {
        //     required: true,
        //     message: "Delivery From is required",
        //     trigger: "change",
        //   },
        // ],
        po_no_select: [
          {
            required: true,
            message: "PO no is required",
            trigger: "change",
          },
        ],
      };

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      setTimeout(() => {
        formRef?.value?.validate(async (valid) => {
          if (valid) {
            if (selectedOrdersProducts?.value?.length <= 0) {
              console.log("validation failed");
              Swal.fire({
                text: "Please select at least one order.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              loading.value = false;
              return;
            }

          let editedrowUnchecked = false
            for(let product of tableData.value){
            if(product.dispatch_quantity !== '' &&  !product.isCheck){
                editedrowUnchecked = true;
                break;
            }
          }
          
          if(editedrowUnchecked){
              Swal.fire({
                title: "Are you sure?",
                text: "Please make sure there are some edited row(s) are not selected!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Submit!",
                cancelButtonText:'Select Again'
              }).then((result) => {
                if (result.isConfirmed) {
                  setCompanyData(formData.value);
                } 
              });
              loading.value = false;
          }else{
            await setCompanyData(formData.value)
          }
          } else {
            loading.value = false;
            return false;
          }
        });
      }, 300);
    };

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getBranchList() {
      let values = {
        company_id: formData.value["supplier_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          consignee_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const onCheckAndInput = (selectedRows, isFormInput = false) => {
      if (selectedRows?.dispatch_quantity === "" && !isFormInput) {
        Swal.fire({
          text: "Please enter dispatch qty first and then select",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        selectedRows.isCheck = false;
      } else if (isFormInput) {
        validateNumber(selectedRows);
        removeFormSelectedOffers(selectedRows);
      } else if (!selectedRows.isCheck && !isFormInput) {
        removeFormSelectedOffers(selectedRows);
      } else {
        selectedOrdersProducts.value.push({
          po_details_id: selectedRows.po_detail_id,
          dispatch_quantity: parseFloat(selectedRows.dispatch_quantity).toFixed(
            4
          ),
        });
      }
    };

    const validateNumber = (selectedRows) => {
      debugger;
      if (/^\d*\.?\d*$/.test(selectedRows.dispatch_quantity)) {
        if(selectedRows.dispatch_quantity == 0){
          selectedRows.dispatch_quantity = selectedRows.dispatch_quantity.slice(0, -1);
        }
        if (
          parseFloat(selectedRows.dispatch_quantity) >
          parseFloat(selectedRows.remainin_qty.split(" ")[0])
        ) {
          selectedRows.dispatch_quantity = "";
          Swal.fire({
            text: "Please enter dispatch qty less than or equl to remaining qty",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        // tempOrderQty.value = selectedRows.dispatch_quantity;
      } else if (selectedRows.dispatch_quantity == "") {
        // tempOrderQty.value = "";
        return;
      } else {
        selectedRows.dispatch_quantity = selectedRows.dispatch_quantity.slice(0, -1);
      }
    };

    const removeFormSelectedOffers = (selectedRows) => {
      selectedRows.isCheck = false;
      let index = selectedOrdersProducts.value
        .map((deteRow) => {
          return deteRow.po_details_id;
        })
        .indexOf(selectedRows?.po_detail_id);
      index !== -1 ? selectedOrdersProducts.value.splice(index, 1) : null;
    };

    async function getPOList() {
      let params = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_warehouse_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PNDING_PO_BY_CLIENT_ID, params)
        .then(({ data }) => {
          debugger;
          po_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTableData() {
      let params = {
        po_ids: formData.value["po_no_select"],
      };
      debugger;
      await store
        .dispatch(ActionsFi.CUST_GET_PO_DETAILS_BY_IDS_DATA, params)
        .then(({ data }) => {
          debugger;
          data.map((product) => {
            product.order_qty = product.order_qty + " " + product.uom;
            product.remainin_qty = product.remainin_qty + " " + product.uom;
            product.dispatch_quantity = "";
            product.isCheck = false;
          });

          tableData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const resetForm = () => {
      debugger;
      formData.value = {
        supplier_select: "",
        supplier_warehouse_select: "",
        delivery_from: "",
        po_no_select: [],
        remark:'',
        is_moving_goods_select: true,
      };
      rules.value = {};
      tableData.value = [];
      selectedOrdersProducts.value = [];
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addRFQmodel,
      supplier_data,
      getCompanyList,
      consignee_branch_data,
      getBranchList,
      tableData,
      multipleTableRef,
      getTableData,
      po_data,
      getPOList,
      resetForm,
      onCheckAndInput,
    };
  },
});
